import React from 'react'
import { blockContentToPlainText } from 'react-portable-text'
import styled from 'styled-components'
import Image from 'gatsby-plugin-sanity-image'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Title from '../title'
import Portable from '../portable'
import SEO from '../seo'
import { formatDate, slugify, artistname } from '../../lib/utils'
import Linkify from 'react-linkify';

const Container = styled.div`
  max-width: calc(1200px - 2rem);
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 56px;
`

const Article = styled.article`
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
  font-family: Catamaran, sans-serif;
  strong, em {
    font-family: Catamaran, sans-serif !important;
  }
  h3 {
    margin-bottom: 42px;
  }
  ul {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  strong {
    font-weight: 600;
  }
  li {
    font-family: Catamaran;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: var(--text);
  }
`

const Center = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`
const Related = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    display: inline-block;
    font-family: Catamaran, sans-serif;
    span {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    a {
      font-family: Catamaran, sans-serif;
      text-decoration: none;
    }
    &:last-of-type span {
      display: none;
    }
  }
`

const NewsArticle = (props) => {

  const { related_artists, title, _rawBodyBlocks, date, image } = props;

  const data = useStaticQuery(graphql`
    query {
      allSanityArtist {
        edges {
          node {
            first_name
            surname
            headshot {
              ...ImageWithPreview
            }
          }
        }
      }
    }
  `)

  const all_artists = data.allSanityArtist.edges.map(n => n.node)

  const mentioned_artists = [];
  const description = _rawBodyBlocks ? blockContentToPlainText(props._rawBodyBlocks).split('.')[0] + '.' : false;
  const body = _rawBodyBlocks ? blockContentToPlainText(_rawBodyBlocks) : false;
  const related_artist_names = related_artists.map(r => artistname(r));

  // array of all artist names
  all_artists.map(artist => {
    if ((body && body.includes(artistname(artist))) || title.includes(artistname(artist))) {
      if (!related_artist_names.includes(artistname(artist))) {
        mentioned_artists.push(artist)
      }
    }
  });

  const slug = '/news/' + slugify(title);

  const formatted_date = formatDate(date);

  const merged_artists = [...related_artists, ...mentioned_artists];


  return (
    <Container>
      <SEO title={title} description={description} slug={slug} image={image?.asset?.publicUrl || false} />
      <Title paddingbottom={related_artists && related_artists.length > 0 ? true: true} black noborder related_artists={merged_artists} subtitle={formatted_date}>{title}</Title>

      {image && image?.asset !== null &&

        <Image
          {...image}
          alt={title}
          style={{
            marginBottom: "36px",
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />

      }
      <Article>
        {_rawBodyBlocks &&
          <Portable blocks={_rawBodyBlocks} />
        }
      </Article>
    </Container>
  )
}

export default NewsArticle;
