import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import NewsArticle from '../components/news_article'

export const query = graphql`
  query NewsArticleQuery($id: String!) {
    article: sanityNews(id: { eq: $id }) {
      id
      image {
        ...ImageWithPreview
        asset {
          publicUrl
        }
      }
      related_artists {
        surname
        first_name
        headshot {
				  ...ImageWithPreview
        }
      }       
      title
      _rawBodyBlocks(resolveReferences: {maxDepth: 10})
      date(formatString: "Do MMMM YYYY")
    }
  }
`


const NewsTemplate = props => {

  const { data, errors } = props
  const article = data && data.article

  return (
    <div>

      <Helmet
        bodyAttributes={{
          class: 'news-page'
        }}
      />

      {article && <NewsArticle {...article} />}
    </div>
  )
}

export default NewsTemplate
